import ContactSection from "./components/ContactSection";
import Container from "./components/Container";
import ContentSection from "./components/ContentSection";
import CTA from "./components/CTA";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { Helmet } from "react-helmet";
import TeamSection from "./components/TeamSection";

function App() {
  return (
    <Container>
      <div className="h-screen">
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            RF Consulting Group | Microsoft and Web Technology Services
          </title>
          <link rel="canonical" href="http://rfcgrp.com/" />
          <meta charset="utf-8" />
          <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta property="og:title" content="RF Consulting Group" />
          <meta
            property="og:description"
            content="Microsoft Certified Power Platform and Microsoft 365 Consultants"
          />
          <meta
            property="og:image"
            content="https://rfcgrpstorageaccount1.blob.core.windows.net/rfcgrp-externalfacingitems/Logo_64x64.png"
          />
          <meta property="og:url" content="https://www.rfcgrp.com" />
          <meta
            name="description"
            content="Microsoft Certified Power Platform and Microsoft 365 Consultants"
          />
          <link
            rel="apple-touch-icon"
            href="https://rfcgrpstorageaccount1.blob.core.windows.net/rfcgrp-externalfacingitems/Logo_64x64.png"
          />
        </Helmet>
        <Header />
        <main>
          <CTA />
          <ContentSection />
          <TeamSection />
          <ContactSection />
        </main>
        <Footer />
      </div>
    </Container>
  );
}

export default App;
