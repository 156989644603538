import React from "react";
import {
  CodeBracketIcon,
  ShareIcon,
  UserGroupIcon,
} from "@heroicons/react/20/solid";

const ContentSection = () => {
  return (
    <div id="services" className="relative bg-white">
      <div className="mx-auto max-w-7xl lg:flex lg:justify-between lg:px-8 xl:justify-end">
        <div className="lg:flex lg:w-1/2 lg:shrink lg:grow-0 xl:absolute xl:inset-y-0 xl:right-1/2 xl:w-1/2">
          <div className="relative h-80 lg:-ml-8 lg:h-auto lg:w-full lg:grow xl:ml-0">
            <img
              alt=""
              src="https://images.unsplash.com/photo-1559136555-9303baea8ebd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-x=.4&w=2560&h=3413&&q=80"
              className="absolute inset-0 h-full w-full bg-gray-50 object-cover"
            />
          </div>
        </div>
        <div className="px-6 lg:contents">
          <div className="mx-auto max-w-2xl pb-24 pt-16 sm:pb-32 sm:pt-20 lg:ml-8 lg:mr-0 lg:w-full lg:max-w-lg lg:flex-none lg:pt-32 xl:w-1/2">
            <p className="text-base font-semibold leading-7 text-blue-700">
              Work Smarter
            </p>
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Let Microsoft 365 do the hard parts
            </h1>
            <p className="mt-6 text-xl leading-8 text-gray-700">
              Microsoft 365 empowers your business by automating routine tasks
              and streamlining complex processes, allowing teams to focus on
              strategic initiatives that drive growth. With tools like Teams,
              SharePoint, and Power Automate, you can enhance collaboration,
              improve productivity, and make data-driven decisions more
              efficiently.
            </p>
            <div className="mt-10 max-w-xl text-base leading-7 text-gray-700 lg:max-w-none">
              <p>
                Having a certified Microsoft specialist guide the optimization
                of these workflows ensures that your business can fully leverage
                the platform’s capabilities, tailoring solutions to meet your
                specific needs and maximizing your return on investment. This
                expert guidance is crucial for navigating the intricacies of
                digital transformation and achieving sustainable success. RF
                Consulting Group can help you through the process.
              </p>
              <ul className="mt-8 space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                  <ShareIcon
                    aria-hidden="true"
                    className="mt-1 h-5 w-5 flex-none text-blue-700"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      The future of work is collaborative.
                    </strong>{" "}
                    Microsoft 365 enhances collaboration by providing integrated
                    tools like Teams, SharePoint, and OneDrive, which allow team
                    members to work together seamlessly from anywhere.
                    Additionally, robust security features, including data
                    encryption and advanced threat protection, ensure that
                    sensitive information remains secure, giving you peace of
                    mind while allowing your team to collaborate efficiently.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <CodeBracketIcon
                    aria-hidden="true"
                    className="mt-1 h-5 w-5 flex-none text-blue-700"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      Power your workflows with Power Platform.
                    </strong>{" "}
                    The Microsoft Power Platform drives business processes and
                    workflows by enabling your business to create custom
                    applications, automate tasks, and analyze data with ease.
                    Tools like Power Apps, Power Automate, and Power BI allow
                    you to streamline operations, improve efficiency, and make
                    informed decisions based on real-time insights, ultimately
                    enhancing overall productivity and agility.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <UserGroupIcon
                    aria-hidden="true"
                    className="mt-1 h-5 w-5 flex-none text-blue-700"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      Connect with your customers.
                    </strong>{" "}
                    The Microsoft 365 ecosystem enhances customer engagement
                    through tools like Teams, which facilitates real-time
                    communication and collaboration, and the Power Platform
                    enables businesses to create custom applications, automate
                    tasks, and gain actionable insights, all of which contribute
                    to richer, more meaningful customer connections.
                  </span>
                </li>
              </ul>

              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
                Need training? Need technical guidance? No problem.
              </h2>
              <p className="mt-6">
                RF Consulting Group offers comprehensive Microsoft 365 training
                and technical service guidance tailored to fit any budget. Our
                team ensures that businesses of all sizes can optimize their use
                of Microsoft 365 tools, enhancing productivity and efficiency.
                Whether you need basic training or advanced technical support,
                RF Consulting Group provides flexible solutions to meet your
                specific needs, ensuring you get the most out of your
                investment.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentSection;
