function CTA() {
  return (
    <section className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Simple solutions for complex problems.
          <br />
        </h2>
        <h4 className="text-xl text-gray-700">
          Let us show you what Microsoft 365 can do for your business.
        </h4>
        <div className="mt-10 flex items-center gap-x-6">
          <a
            href="https://outlook.office365.com/owa/calendar/Consultation@rfcgrp.com/bookings/"
            className="rounded-md bg-blue-700 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
          >
            Book a free consultation today!
          </a>
          <a
            href="#services"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Learn more <span aria-hidden="true">→</span>
          </a>
        </div>
      </div>
    </section>
  );
}

export default CTA;
